/* Zuccoli - Colours */
$color-zuccoli-green: #00515A;
$color-zuccoli-green-light: #7CA5AA;
$color-zuccoli-green-lighter: #B3CBCE;
$color-zuccoli-blue: #8BC3E5;
$color-font-body: $color-zuccoli-green;
$color-white: #FFF;

body.current-site-zuccoli {
/* Global */
	/* Base Selectors */
		color: $color-font-body;
		a {
			color: $color-zuccoli-green;
			&:hover {
				color: $color-zuccoli-green-light;
			}
		}
	/* Base Classes */
	/* Buttons */
		.button {
			background-color: $color-zuccoli-green;
			color: $color-white;
			&:after {
				color: $color-zuccoli-green-light;
			}
			&:hover {
				background: $color-zuccoli-green-light;
				border-color: $color-zuccoli-green-light;
				color: $color-zuccoli-green;
				&:after {
					color: $color-zuccoli-green;
				}
			}
			/* Button Icons */
				&--icon-right:after {
					color: $color-zuccoli-green-light;
				}
				&--icon-play:after {
				}
				&--icon-play-large:after {
				}
			/* Button Sizes */
				&--small {
					&:after {
					}
				}
				&--large {

					&:after {
					}
				}
			/* Button Variations */
				&--white {
					background-color: $color-white;
					color: $color-zuccoli-green;
					&:after {
						color: $color-zuccoli-green-light;
					}
				}
				&--white-stroke {
					border-color: $color-white;
					background-color: transparent;
					&:after {
						color: $color-white;
					}
				}
				&--default {
					background-color: $color-white;
					border-color: $color-zuccoli-green-light;
					color: $color-zuccoli-green;
					&:after {
						color: $color-zuccoli-green;
					}
					&:hover {
						background: $color-zuccoli-green-light;
						border-color: $color-zuccoli-green-light;
						color: $color-zuccoli-green;
						&:after {
							color: $color-zuccoli-green;
						}
					}
				}
				&--light-on-dark {
					background-color: $color-zuccoli-green-lighter;
					color: $color-zuccoli-green;
					&:after {
						color: $color-zuccoli-green-light;
					}
					&:hover {
						border-color: $color-white;
						color: $color-white;
						&:after {
							color: $color-white;
						}
					}
				}
		}
	/* Image - Preloader */
		.loader-container {
			background-color: #F1F1F1;
			&.for-dark {
				background-color: $color-zuccoli-blue;
			}
			&.for-light {
				background-color: #F1F1F1;
			}
			.loader {
				border-top: 1.1em solid $color-zuccoli-green-light;
				border-right: 1.1em solid $color-zuccoli-green-light;
				border-bottom: 1.1em solid $color-zuccoli-green-light;
				border-left: 1.1em solid transparent;
				&.for-dark {
					border-top: 1.1em solid $color-zuccoli-green;
					border-right: 1.1em solid $color-zuccoli-green;
					border-bottom: 1.1em solid $color-zuccoli-green;
					border-left: 1.1em solid $color-zuccoli-green-light;
				}
				&.for-light {
					border-top: 1.1em solid #eee;
					border-right: 1.1em solid #eee;
					border-bottom: 1.1em solid #eee;
					border-left: 1.1em solid $color-zuccoli-blue;
				}
			}
		}
	/* Header */
		.header {
			/* Header - Layout */
				.desktop-menu {
					/* Elements */
						.logo {
							width: 254px;
						}
					/* Activate Mobile Menu */
						@media only screen and (max-width: 1400px) {
							.menu-main-menu-desktop-container {
								display: none;
							}
						}
					/* Desktop Menu Layout */
						ul.menu {
							li {
								&.menu-item-has-children,
								&.has-submenu {
									&:after {
										color: $color-zuccoli-blue;
									}
								}
								&.menu-item-has-children {
									a:before {
										background-color: $color-zuccoli-blue;
									}
								}
								&.current-page-ancestor a,
								&.current-menu-item a {
									&:after {
										background-color: $color-zuccoli-blue;
									}
									&:before {
										background-color: #D8E4E6;
									}
								}
								&:first-child {
									&:before {
										background-color: transparent;
									}
								}
								ul.sub-menu {
									li {
										&:before {
											background-color: $color-zuccoli-green-light;
										}
										&:first-child {
											a:after,
											&:before {
												background-color: transparent;
											}
										}
										a {
											color: $color-white;
										}
									}
								}
								&.current-page-ancestor,
								&.current-menu-item {
									ul.sub-menu li {
										a {
											&:after {
												background-color: $color-zuccoli-green-light;
											}
										}
										&:first-child {
											a:after,
											&:before {
												background-color: transparent;
											}
										}
									}
								}
								&:hover .sub-menu {
								}
							}
						}
					/* Sticky Desktop Menu */
						&--sticky {
							background-color: $color-white;
							border-color: #B2CACD;
							a.logo {
								width: 166px;
								height: 46px;
							}
							/* When sticky: Dark Menu Color */
								ul.menu {
									li {
										&:before {
											background-color: transparent;
										}
										&.menu-item-has-children,
										&.has-submenu {
											&:after {
												color: $color-zuccoli-blue;
											}
										}
										a {
											color: $color-zuccoli-green;
										}
										&.current-page-ancestor a,
										&.current-menu-item a {
											&:after {
												background-color: $color-zuccoli-blue;
											}
										}
										&.current-page-ancestor:first-child a:after,
										&.current-menu-item:first-child a:after {
											background-color: transparent;
										}
									}
									li .sub-menu {
										background-color: $color-zuccoli-green;
										li {
											border-bottom: $color-zuccoli-green-light;
										}
										a {
											color: $color-white;
										}
									}
									li:hover .sub-menu {
									}
								}
								.socials a {
									color: $color-zuccoli-green;
								}
								p.hero-title,
								h1.hero-title {
									color: $color-zuccoli-green;
								}
						}
					/* Light Desktop Header - Colours */
						&--light-header {
							ul.menu {
								li {
									&:before {
										background-color: transparent;
									}
									&.menu-item-has-children,
									&.has-submenu {
										&:after {
											color: $color-white;
										}
									}
									a {
										color: $color-white;
									}
									&.current-page-ancestor a,
									&.current-menu-item a {
										&:after {
											background-color: $color-white;
										}
										&:before {
											background-color: $color-zuccoli-blue;
										}
									}
								}
								li .sub-menu {
									background-color: $color-zuccoli-green;
									li {
										border-color: transparent;
										&:before {
											background-color: $color-zuccoli-green-light;
										}
									}
									a {
										color: $color-white;
									}
								}
								li:hover .sub-menu {
								}
							}
							.socials a {
								color: $color-white;
							}
							p.hero-title,
							h1.hero-title {
								color: $color-white;
							}
						}
					/* Dark Desktop Header - Colours */
						&--dark-header {
							ul.menu {
								li {
									&:before {
									}
									&.menu-item-has-children,
									&.has-submenu {
										&:after {
											color: $color-zuccoli-blue;
										}
									}
									&.menu-item-has-children {
										&:after {
											color: $color-zuccoli-blue;
										}
									}
									a {
										color: $color-zuccoli-green;
									}
									&.current-page-ancestor a,
									&.current-menu-item a {
										&:after {
											background-color: $color-zuccoli-blue;
										}
									}
								}
								li .sub-menu {
									background-color: $color-zuccoli-green;
									li {
										&:before {
											background-color: $color-zuccoli-green-light;
										}
									}
									&:first-child {
										&:before {
											background-color: transparent;
										}
									}
									a {
										color: $color-white;
									}
								}
								li:hover .sub-menu {
								}
							}
							.socials a {
								color: $color-zuccoli-green;
							}
							p.hero-title,
							h1.hero-title {
								color: $color-zuccoli-green;
							}
						}
					/* Media Queries */
						@media only screen and (max-width: 767px) {
							.logo {
								width: 165px;
							}
						}
				}
			&--light-header {
				.desktop-menu {
					ul.menu {
						li {
							&.menu-item-has-children {
								&:after {
									color: #D8E4E6;
								}
							}
							&.current-menu-item {
								a:after {
									background-color: #D8E4E6;
								}
							}
						}
					}
				}
			}
		}
	/* Hero */
		.slideshow-hero {
			background-color: $color-white;
			&.facets {
				.slide {
					p.hero-title,
					h1.hero-title {
					}
				}
			}
			/* Shared between Slides */
				.slide {
					&:after {
						color: #ffffff;
					}
					&:before {
						color: $color-white;
					}
					p.hero-title,
					h1.hero-title {
					}
					&--text {
						p.hero-title,
						h1.hero-title {
						}
					}
					.facets {
						.facet .facetwp-facet {
						}
						.slide {
						}
					}
					span.picturefill-background {
					}
					.slide-content-wrap {
					}
					.slide-content {
						.button {
						}
					}
					.buttons {
						.button {
						}
					}
					.blockquote {
						p {
						}
					}
					.wash {
						&--option-1 {
							background-color: rgba(0, 0, 0, 0.25);
						}
						&--option-2 {
							background-color: rgba(0, 0, 0, 0.5);
						}
						&--option-3 {
							background-color: rgba(0, 0, 0, 0.75);
						}
					}
				}
				.facets {
					.facet-reset-button {
						&:hover {
							cursor: pointer;
						}
					}
					.facet {
						.facetwp-facet {
							&:before {
							}
							.facetwp-slider-label {
							}
						}
						.facetwp-slider-reset {
						}
						.noUi-connect {
							background: $color-zuccoli-green-lighter;
						}
						.noUi-handle {
							background: $color-zuccoli-green;
						}
						.noUi-handle:after,
						.noUi-handle:before {
						}
						.noUi-target {
							background: #FFF;
							border: 1px solid $color-zuccoli-green-light;
						}
					}
				}
			/* Video Loop Slide - Image fallback on mobile */
				.video-background {
				}
				.video-fallback-image {
				}
				.slider__video {
					video {
					}
				}
		}
	/* Hamburger */
		.hamburger-wrap {
			&.header--dark-header {
				#nav-icon {
					span {
						background-color: $color-zuccoli-green;
					}
					&:hover span {
						background-color: $color-zuccoli-green-light;
					}
					&.open:hover span {
						background-color: $color-white;
					}
					&.open span {
						background-color: $color-white;
					}
				}
			}
			/* Icon */
				#nav-icon {
					span {
						background: $color-white;
					}
					&:hover span {
						background-color: $color-white;
					}
					&.open:hover span {
						background-color: $color-white;
					}
					&.open span {
						background-color: $color-white;
					}
				}
			/* Media Queries */
				@media only screen and (max-width: 1400px) {
					width: 100%;
					display: block;
					#nav-icon {
						display: block;
						margin: 57px 60px 0 0;
					}
				}
				@media only screen and (max-width: 767px) {
					#nav-icon {
						margin: 27px 30px 0 0;
					}
				}
		}
	/* Mobile Menu */
		#mobile-menu {
			background-color: $color-zuccoli-green;
			.logo {
				max-width: 350px;
				width: 50%;
			}
			/* Base Styles - Level 0 */
				.menu {
					.sub-menu li {
					}
				}
				ul {
					li {
						&.active > a {
							i {
							}
						}
						a {
							color: $color-white;
							&:after {
								background: transparent;
							}
							&:hover {
								color: $color-white;
								&:after {
									background-color: $color-white;
								}
							}
						}
						&.current_page_item > a {
							color: $color-white;
							&:after {
								background-color: $color-white;
							}
						}
						& i {
							color: $color-white;
						}
						.menu-opener {
							i {
							}
						}
					}
				}
			/* Sub Menu: Level 1 */
				.sub-menu {
					li {
						&.active > a {
							i {
							}
						}
						a {
							color: #CCF4FA;
							&:hover {
								&:after {
									background: transparent;
								}
							}
							.menu-opener {
								i {
									color: #CCF4FA;
								}
							}
						}
					}
				}
			/* Sub Menu: Level 2 */
				.sub-menu .sub-menu {
					li a {
						color: $color-white;
					}
				}
			/* Social in menu */
				.social {
					&:before {
						background-color: $color-white;
					}
					a {
						color: $color-white;
						i {
						}
						.label {
						}
					}
				}
				.sub-menu li.current_page_item a:after {
					background-color: transparent;
				}
		}
	/* Footer */
		.footer {
			background-color: $color-zuccoli-green;
			.top {
				.column-one {
					&:after {
						background-color: #4D858B;
					}
					.logo {
					}
					.menu li {
						a {
						}
					}
				}
				.column-two {
					&:after {
						background-color: #4D858B;
					}
					h3 {
					}
					.post {
					}
					.date {
						color: $color-zuccoli-green-light;
					}
				}
				.column-three {
					form {
						.gform_body {
							ul {
							}
							li {
							}
						}
						.gform_footer {
							button {
								color: $color-zuccoli-green;
								border-color: $color-zuccoli-green-lighter;
								background-color: $color-zuccoli-green-lighter;
								i {
									color: $color-zuccoli-green-light;
								}
								&:hover {
									border-color: $color-zuccoli-green-light;
									color: $color-zuccoli-green;
									background-color: $color-zuccoli-green-light;
								}
							}
						}
						.gfield_description.validation_message,
						.validation_error {
							color: $color-white;
						}
						input {
							border: 1px solid $color-white;
						}
					}
				}
			}
			.bottom {
				p, a {
					color: #4C848B;
				}
				a:hover {
					color: $color-white;
				}
				.column-one {
					p, a {
					}
				}
				.column-two {
					ul {
					}
					li {
						a {
						}
					}
				}
			}
			h3 {
				color: $color-white;
				&:after {
					color: $color-zuccoli-green-light;
				}
			}
			.newsletter-subscribe {
			}
			.stay-connected {
				h3 {
				}
				.socials a {
					color: $color-white;
				}
			}
		}
	/* Pagination */
		.wp-pagenavi {
			span,a {
				border: 1px solid $color-zuccoli-green-lighter;
				color: $color-zuccoli-green;
			}
			span.current {
				background-color: $color-zuccoli-green;
				color: $color-white;
			}
		}
	/* Back to top */
		.back-to-top {
			a {
			}
			span {
				color: $color-zuccoli-green;
			}
			&:before {
				color: $color-zuccoli-green;
			}
		}
	/* Cookie Notice */
		.cookie-notice {
			background-image: linear-gradient(-127deg, #0099CC 10%,  #00C9E8 93%);
			p {
				color: $color-white;
			}
			i.icon {
				color: $color-white;
			}
			a {
				color: $color-white;
			}
		}
	/* Registration Form (popout) */
		#registration-form-popout {
			background: $color-zuccoli-green;
			.close-button {
				color: $color-white;
			}
			h2 {
				color: $color-white;
			}
			p {
				color: $color-white;
			}
			label {
				color: $color-white;
				a {
					color: $color-white;
				}
			}
			input[type="text"] {
				border: 1px solid $color-zuccoli-green;
			}
			.gfield_checkbox {
				label,
				a {
				}
			}
		}
/* Headings */
	h1 {

	}
	h2 {
		color: $color-zuccoli-green;
	}
	h3 {

	}
	h4 {
		color: $color-zuccoli-green;
	}
	.heading--arrow-right:after {
	}
	p {
		color: $color-zuccoli-green;
	}
	sup {
	}
/* Forms */
	.gform_wrapper {
		color: $color-zuccoli-green;
		/* Basics */
			a {
				color: $color-zuccoli-green;
			}
			textarea {
				border: 1px solid $color-zuccoli-green-light;
			}
			input[type="text"] {
				border: 1px solid $color-zuccoli-green-light;
				&:focus {
					border: 1px solid $color-zuccoli-green;
				}
			}
		/* Footer */
			.gform_footer {
				button {
					i {
					}
				}
			}
		/* Checkboxes */
			.gfield_checkbox {
				input[type="checkbox"] + label span.custom-checkbox {
				    background: url(../../images/form-elements/checkbox-zuccoli.png) left top no-repeat;
				    background-size: cover;
				}
				input[type="checkbox"]:checked + label span.custom-checkbox {
				    background: url(../../images/form-elements/checkbox-zuccoli.png) -15px top no-repeat;
				    background-size: cover;
				}
			}
		/* Validation & Confirmation */
			.validation_error {
				color: $color-white;
			}
			.gfield_description.validation_message {
				color: $color-white;
			}
			li.gfield_error {
				input[type="text"], textarea {
					border-bottom: 1px solid red;
				}
			}
		/* Placeholders */
			::-webkit-input-placeholder { opacity: 1; font-size: 15px; color: $color-zuccoli-green; }
			::-moz-placeholder  { opacity: 1; font-size: 15px; color: $color-zuccoli-green; } /* firefox 19+ */
			:-ms-input-placeholder  { opacity: 1; font-size: 15px; color: $color-zuccoli-green; } /* ie */
			input:-moz-placeholder  { opacity: 1; font-size: 15px; color: $color-zuccoli-green; }
		/* Color Variations */
			&.form--light_wrapper {
				.gform_body {
					.gfield_checkbox {
						 label {
							a {
								&:after {
									background-color: $color-zuccoli-blue;
								}
							}
						}
					}
				}
				.gform_footer {
					button {
						background-color: $color-zuccoli-green-light;
						color: $color-zuccoli-green;
						border-color: $color-zuccoli-green-light;
						&:hover {
							background-color: $color-white;
						}
					}
				}
			}
	}
	.gform_confirmation_message {
		color: $color-white;
	}
/* Layout : Latest News */
	.latest-news-layout {
		background-color: $color-white;
		color: $color-white;
		h2 {
			color: $color-zuccoli-green;
		}
	}
/* Layout : Latest News : Article Tiles */
	.news-article {
		background: #F8F8F8;
		border: 1px solid $color-zuccoli-green-lighter;
		/* Shared */
			.category-tag {
				background-color: $color-white;
				color: $color-zuccoli-green;
				border: 1px solid $color-zuccoli-green;
			}
			h3 {
				color: $color-zuccoli-green;
			}
			.news-article-content {
			}
			.news-article-excerpt {
				color: $color-zuccoli-green;
			}
			.post-date {
				background-color: $color-zuccoli-green;
				color: #FFFFFF;
			}
			.news-featured-image {
			}
		/* Tall Article */
			&--tall {
				h3 {
					&:after {
						background-color: $color-zuccoli-green;
					}
				}
			}
	}
/* Layout : Latest News : Latest News Layout */
	.category-list {
		li {
			&.current-menu-item {
				a {
					color: $color-white;
					background-color: $color-zuccoli-green;
				}
			}
			a {
				border: 1px solid $color-zuccoli-green-lighter;
				color: $color-zuccoli-green;
				&:hover {
					color: $color-zuccoli-green;
					background-color: $color-zuccoli-green-lighter;
				}
			}
		}
	}
/* Layout : Latest News : Single */
	.single-post {
		h1 {
			color: $color-zuccoli-green;
		}
	}
	.post-meta {
		p {
			color: $color-zuccoli-green;
			border: 1px solid $color-zuccoli-green-lighter;
		}
	}
/* Layout : Blockquote  */
	.blockquote-layout {
		p {
			color: $color-zuccoli-green;
		}
	}
/* Layout : Image/Text (alternating) */
	.cards-alternating {
		.wrap {
		}
		.card {
			&--left {
				.card-image {
					.icon-play-circle {
						color: $color-white;
					}
				}
				.card-content {
					h2 {
					}
					.card-text {
						p {
						}
					}
				}
			}
			&--right {
				.card-image {
				}
				.card-content {
					.card-text {
					}
				}
			}
			/* Shared */
				h2 {
				}
				h3 {
					color: $color-zuccoli-green;
				}
				.quote-slider {
					.quote-slide {
						.quote {
							&:before {
							}
							&:after {
							}
							p {
							}
						}
					}
					.testimonial-arrow-wrap {
					}
				}
		}
	}
/* Layout : Accordion */
	.accordions {
		.accordion {
			h3 {
				background: $color-zuccoli-green-lighter;
				border: 1px solid $color-zuccoli-green-light;
				color: $color-zuccoli-green;
				&:after {
					color: $color-zuccoli-green;
				}
				&.open {
					background-color: $color-white;
					&:after {
						color: $color-zuccoli-green;
					}
				}
			}
			.open-accordion {
				border-color: $color-zuccoli-green-light;
			}
			.accordion-download-link {
				&:after {
					background-color: $color-zuccoli-blue;
				}
			}
		}
	}
/* Layout : Pods */
	.pods {
		.pod {
			background-color: $color-zuccoli-green;
		}
	}
/* Layout : Buttons */
	.buttons-layout {
		.button {
		}
	}
/* Layout : Contact Us */
	.contact-layout {
		.contact-details {
			h2,
			.address,
			.phone,
			.email {
			}
			.address {
				p {
				}
			}
			.contact-detail {
			}
			.phone-row {
				&:before {
					color: $color-zuccoli-green;
				}
				.phone {
					color: $color-zuccoli-green;
					&:after {
					}
					&:hover {
						&:after {
							background-color: $color-zuccoli-blue;
						}
					}
				}
			}
			.email-row {
				&:before {
					color: $color-zuccoli-green;
				}
				.email {
					color: $color-zuccoli-green;
					&:after {
						background-color: $color-zuccoli-blue;
					}
				}
			}
			.opening-hours {
				.appointment {
					p {
						color: $color-zuccoli-green-light;
					}
					.phone {
						color: $color-zuccoli-green-light;
					}
					i.icon-phone-icon {
						color: $color-zuccoli-green-light;
					}
				}
				.cell {
					&--days {
					}
					&--times {
						border-left: 1px solid $color-zuccoli-green-lighter;
					}
				}
			}
		}
		.contact-form {
			h2 {

			}
			.gform_confirmation_message {
				color: $color-zuccoli-green;
			}
		}
	}
/* Layout : Building Partners */
	.building-partners {
		.building-partner {
			border: 1px solid $color-zuccoli-green-light;
			.building-partner-logo {
				border-bottom: 1px solid $color-zuccoli-green-light;
			}
			.building-partner-content {
				h3 {

				}
			}
		}
	}
/* Layout : Display Village */
	.display-village {
		.display-village-content {
			.phone-row {
				&:before {
					color: $color-zuccoli-green;
				}
				.phone {
					color: $color-zuccoli-green;
					&:after {
					}
					&:hover {
						&:after {
							background-color: $color-zuccoli-blue;
						}
					}
				}
			}
			.email-row {
				&:before {
					color: $color-zuccoli-green;
				}
				.email {
					color: $color-zuccoli-green;
					&:after {
						background-color: $color-zuccoli-blue;
					}
				}
			}
			.opening-hours {
				.appointment {
					p {
						color: $color-zuccoli-green-light;
					}
					.phone {
						color: $color-zuccoli-green-light;
					}
					i.icon-phone-icon {
						color: $color-zuccoli-green-light;
					}
				}
				.cell {
					&--days {
					}
					&--times {
						border-left: 1px solid $color-zuccoli-green-lighter;
					}
				}
			}
		}
	}
/* Layout : House & Land (page) */
	.property-listing-result {
		border: 1px solid $color-zuccoli-green-light;
		&:hover {
			color: $color-zuccoli-green;
			.button {
				background-color: $color-zuccoli-green-lighter;
			}
		}
		.featured-image {
			.price {
				background-color: $color-zuccoli-green;
				color: $color-white;
			}
		}
		.content-area {
			h3 {
			}
			.lot-number {
				p {
				}
			}
		}
		.property-stat {
			color: $color-zuccoli-green-light;
			&:before {
				color: $color-zuccoli-green;
			}
		}
	}
	.facetwp-template {
		.property-listing-result {
		}
	}
/* Layout : House & Land (single) */
	.property-listing {
		.property-sidebar {
			h1 {
			}
			.property-stats {
				.property-stat {
					color: $color-zuccoli-green-lighter;
					&:before {
						color: $color-zuccoli-green;
					}
				}
			}
			.property-address,
			.property-lot-number {
			}
			.property-package-expiry {
				p {
				}
			}
			.property-price {
				background-color: $color-zuccoli-green;
				color: $color-white;
			}
			.property-buttons {
				.button {
					&:after {
					}
				}
				.dkpdf-button {
					background-color: $color-white;
					border-color: $color-zuccoli-green-light;
					color: $color-zuccoli-green;
					&:hover {
						background-color: $color-zuccoli-green-light;
					}
				}
			}
			.property-logo {
			}
			.property-global-contact {
				border: 1px solid $color-zuccoli-green-light;
				a {
				}
			}
		}
		.property-content {
			.property-enquiry-button {
			}
			.property-carousel-nav {
			}
			.property-description {
			}
		}
	}
	/* House & Land Enquiry Form (popout) */
		#house-land-enquiry-popout {
			background: $color-zuccoli-green;
			.close-button {
				color: $color-white;
			}
			h2 {
				color: $color-white;
			}
			p {
				color: $color-white;
			}
			label {
				color: $color-white;
				a {
					color: $color-white;
				}
			}
			input[type="text"] {
				border: 1px solid $color-zuccoli-green;
			}
			.gfield_checkbox {
				label,
				a {
				}
			}
		}
/* Vendor - Dropkick */
	.dk-selected {
	    border: 1px solid $color-zuccoli-green-light;
	    background-color: $color-white;
	    color: $color-zuccoli-green;
	}
	.dk-select.dk-select-open-down,
	.dk-select.dk-select-open-up {
	    border-color: $color-zuccoli-green;
	    .dk-selected {
	        border-color: $color-zuccoli-green;
	    }
	    .dk-selected:before {
	        background-color: $color-zuccoli-green;
	        color: $color-white;
	    }
	}
	.dk-selected:before {
	    background-color: $color-zuccoli-green-light;
	}
	.dk-selected:hover {
	    border-color: $color-zuccoli-green-light;
	}
	.dk-selected-disabled {
	    color: #BBBBBB;
	}
	.dk-select-options {
	    background-color: $color-white;
	    border-color: $color-zuccoli-green;
	}
	ul li.dk-option {
	    background-color: $color-white;
	    color: $color-zuccoli-green;
	    border-bottom: 1px solid $color-zuccoli-green;
	}
	.dk-option-selected {
	    background-color: $color-zuccoli-green-light;
	    color: $color-white;
	}
	.dk-select-options-highlight .dk-option-selected {
	    background-color: $color-zuccoli-green-light;
	}

	.dk-select-options .dk-option-highlight {
	    background-color: $color-zuccoli-green-light;
	    color: $color-white;
	}
	.dk-select-options .dk-option-disabled {
	    color: #BBBBBB;
	    background-color: transparent;
	}
	.dk-optgroup {
	    border: solid #CCCCCC;
	}
	.dk-optgroup-disabled {
	    color: #BBBBBB;
	}
	.dk-select-disabled {
	    color: #BBBBBB;
	}
	.dk-select-disabled .dk-selected:hover,
	.dk-select-disabled .dk-selected:focus {
	    border-color: inherit;
	}
	.dk-select-disabled .dk-selected:hover:before,
	.dk-select-disabled .dk-selected:focus:before {
	    border-top-color: inherit;
	}
	.dk-select-disabled .dk-selected:hover:after,
	.dk-select-disabled .dk-selected:focus:after {
	    border-left-color: inherit;
	}
	.form--dark .dk-selected {
	    border-color: $color-zuccoli-green-light;
	}
	.select--compact .dk-selected,
	.select--compact .dk-option {
	    border-color: $color-zuccoli-green;
	}
	.select--compact .dk-selected:hover {
	    background-color: $color-zuccoli-green;
	}
	.select--compact .dk-select-options {
	    border-color: $color-zuccoli-green;
	}
}